<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row row-cols-sm-2 row-cols-md-5 mb-4">
      <div class="col">
        <router-link to="/parameter/parameter-produk-kategori"
          ><a-button type="primary" style="text-align:left;" block>Kategori</a-button></router-link
        >
      </div>
      <div class="col">
        <router-link to="/parameter/parameter-produk-merk"
          ><a-button type="link" style="text-align:left;" block>Merk</a-button></router-link
        >
      </div>
      <div class="col">
        <router-link to="/parameter/parameter-produk-supplier"
          ><a-button type="link" style="text-align:left;" block>Supplier</a-button></router-link
        >
      </div>
      <div class="col">
        <router-link to="/parameter/parameter-produk-tipe"
          ><a-button type="link" style="text-align:left;" block>Tipe</a-button></router-link
        >
      </div>
      <div class="col">
        <router-link to="/parameter/parameter-produk-sub-kategori"
          ><a-button type="link" style="text-align:left;" block>Sub Kategori</a-button></router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-select
          :options="[
            { value: null, label: 'Semua' },
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          class="mr-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="status"
        ></a-select>
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="m-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-2"
          title="Tambah Kategori"
          type="primary"
          @click="
            () => {
              formState = {}
              formState.status_approval = 0
              formState.type = 1
              modal1Visible = true
              formState.title_state = 'Tambah Kategori'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="table-responsive ">
      <md-table
        :columns="columns"
        :data-source="data"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #customTitle>
          <span>
            No
          </span>
        </template>

        <template #status="{ record }">
          <a-tag v-if="record.active" color="#108ee9">{{ record.status_text }}</a-tag>
          <a-tag v-else color="grey">{{ record.status_text }}</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Ubah Kategori">
              <a
                href="javascript: void(0);"
                class="btn  btn-sm btn-light"
                @click="editKategori(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="postDelete(record.id)"
              >
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
  <a-modal
    v-model:visible="modal1Visible"
    :title="formState.title_state"
    width="700px"
    :destroy-on-close="true"
    :mask-closable="false"
    @ok="modal1Visible = false"
    ref="modalRef"
  >
    <pre v-if="!true">{{ formState }}</pre>
    <template #footer>
      <a-button
        :loading="isPosting"
        key="submit"
        type="primary"
        @click="submit"
        :hidden="formState.readOnly"
        >Simpan</a-button
      ></template
    >

    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      class="myform"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama Kategori"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="name"
          >
            <a-input
              width="100%"
              v-model:value="formState.name"
              :disabled="formState.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Status"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            name="active"
          >
            <a-radio-group :disabled="formState.readOnly" v-model:value="formState.active" required>
              <a-radio :value="true">Aktif</a-radio>
              <a-radio :value="false">Tidak Aktif</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, provide, toRefs, computed } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import qs from 'qs'
import moment from 'moment'

const acolumns = [
  {
    dataIndex: 'id',
    align: 'center',
    slots: {
      title: 'customTitle',
      customRender: 'no',
    },
    title_xls: 'category id',
  },

  {
    title: 'Nama Kategori',
    dataIndex: 'name',
    align: 'center',
    title_xls: 'category name',
  },

  {
    title: 'Status',
    slots: { customRender: 'status' },
    align: 'center',
    title_xls: 'category status',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]

export default defineComponent({
  name: 'MFilter',
  components: {},
  setup() {
    const loading = ref(false)
    const modal1Visible = ref(false)
    const formState = ref({})
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const status = ref(null)

    const data = ref([])
    const q = ref('')

    const isPosting = ref(false)
    const value1 = ref(true)
    const value2 = ref(false)
    const errorMessage = ref(null)
    const modalRef = ref('')

    const formRef = ref('')

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    columns.value = acolumns

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/categories', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `parameter-kategori_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        q: q.value,
        status: status.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/categories', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const editKategori = id => {
      apiClient
        .get(`/api/categories/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Edit Kategori'
          formState.value.state = 'edit'
          formState.value.readOnly = false
        })
        .catch(error => console.error(error))
    }

    const submit = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Tambah Area',
          content: 'Apakah anda ingin melanjutkan tambah Kategori ?',

          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            apiClient
              .post('/api/categories', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan,Isi kolom sesuai nama'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit Role',
          content: 'Apakah anda ingin melanjutkan Edit Role ?',
          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            // TODO: validasi
            apiClient
              .post('/api/categories/' + formState.value.id + '?_method=PUT', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan, Isi kolom sesuai nama'))
              .finally(e => {
                isPosting.value = false
              })
          },
          onCancel() {},
        })
      }
    }

    const postDelete = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus',
        content: 'Apakah anda ingin Hapus kategori ?',
        onOk() {
          apiClient
            .delete(`/api/categories/${id}`)
            .then(response => {
              const data = response.data
              formState.value = data
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }

    provide('formState', formState)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const search = () => {
      fetchData()
    }

    onMounted(() => {
      fetchData()
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }
    const visible = ref(false)

    const validate = () => {
      formRef.value
        .validate()
        .then(() => {})
        .catch(error => {
          console.error('error', error)
        })
    }

    const rules = {
      name: {
        required: true,
        message: 'Masukan Nama Kategori',
      },
      active: {
        required: true,
        message: 'status belum dipilih',
        type: 'boolean',
      },
    }

    return {
      modal1Visible,
      loading,
      visible,
      handleOk,
      formState,
      setModal1Visible,
      data,
      columns,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      searchText,
      searchInput,
      fetchXlsx,
      q,
      search,
      startRow,
      state,
      ...toRefs(state),
      fetchData,
      handleTableChange,
      handleReset,
      handleSearch,
      onSelectChange,
      status,
      submit,
      isPosting,
      editKategori,
      postDelete,
      value1,
      value2,
      errorMessage,
      validate,
      rules,
      modalRef,
      formRef,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
